import { defineNuxtRouteMiddleware } from '#imports';

const checkAuthUser = (attempt: number) => {
  const sitisAuth = useSitisAuth();
  const isLoggedIn = sitisAuth.isLoggedIn.value;

  if (!isLoggedIn && attempt <= 10) {
    setTimeout(() => {
      checkAuthUser(attempt + 1);
    }, 1000);
    return
  }

  const { $config } = useNuxtApp();
  const lcDomain = $config.public.baseDomainLc;

  window.location.href = lcDomain;
};
export default defineNuxtRouteMiddleware(() => {
  const isAuthToken = Boolean(useCookie("authToken").value);
  if (isAuthToken) {
    checkAuthUser(0);
  }
});
